import React, { useEffect, useState } from "react";
import { Constants, MeetingProvider } from "@videosdk.live/react-sdk";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { ILSContainer } from "./interactive-live-streaming/ILSContainer";
import { MeetingAppProvider } from "./MeetingAppContextDef";
import { appEndStream } from "./api";
import moment from "moment";

const App = () => {
  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  const handleMeetingEnd = async () => {
    const userToken = localStorage.getItem('userToken')
    const studioCode = localStorage.getItem('studioCode')
    const res = await appEndStream({ token: userToken, liveUrl: `https://live.tiwiflix.io/?code=${studioCode}` })

    if (res.ok) {
      localStorage.clear()
      window.open('https://app.tiwiflix.io/livestream', '_blank')
      window.close()
    }
  }

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);
  useEffect(() => {
    if (isMeetingLeft) {
      handleMeetingEnd()
      // const userToken = localStorage.getItem('userToken')
      // console.log(userToken);
      // const studioCode = localStorage.getItem('studioCode')
      // appEndStream({ token: userToken, liveUrl: `https://live.tiwiflix.io/?code=${studioCode}` })

      // // window.close()
      // // window.open('https://app.tiwiflix.io/livestream', '_blank')
    }

  }, [isMeetingLeft]);

  useEffect(() => {
    const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      let parsedStreamTime
      const streamTime = localStorage.getItem('streamTime')
      if (streamTime) {
        parsedStreamTime = JSON.parse(streamTime)
        if (moment(parsedStreamTime).isAfter(moment().subtract(1, 'hour'))) {
          return
        }
        else {
          handleMeetingEnd()
        }
      }
    }, 5000)

    return () => clearInterval(intervalId); //This is important

  }, [timeLeft])


  return (
    <>
      {!isMeetingStarted && <Header />}
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",
              mode: meetingMode,
              multiStream: false,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <ILSContainer
              onMeetingLeave={() => {
                handleMeetingEnd()
                // setToken("");
                // setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
              meetingMode={meetingMode}
              setMeetingMode={setMeetingMode}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
      ) : (
        <JoiningScreen
          participantName={participantName}
          setParticipantName={setParticipantName}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={() => {
            setMeetingStarted(true);
            console.log(token);
          }}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
          meetingMode={meetingMode}
          setMeetingMode={setMeetingMode}
        />
      )}
      {/* <Footer /> */}

    </>
  );
};

export default App;
