import { useEffect, useState } from "react";

export function LeaveScreen({ setIsMeetingLeft }) {
  const [isCreator, setIsCreator] = useState(false)

  useEffect(() => {
    const isCreator = localStorage.getItem('isCreator')
    if (isCreator) {
      setIsCreator(true)
    }
  }, [])
  return (
    <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center">
      <h1 className="text-white text-4xl">You left the meeting!</h1>
      <div className="mt-12 flex items-center gap-4">
        {isCreator && <button
          className="`w-full bg-[#ff9814] text-[#0E0E0E] font-[500] px-16 py-3 rounded-[30px] text-sm"
          onClick={() => {
            setIsMeetingLeft(false);
          }}
        >
          Rejoin the Stream
        </button>}
        {isCreator ? <button
          className="`w-full bg-[#ff9814] text-[#0E0E0E] font-[500] px-16 py-3 rounded-[30px] text-sm"
          onClick={() => {
            window.open('https://app.tiwiflix.io', '_self')
          }}
        >
          End Stream
        </button> : <button
          className="`w-full bg-[#ff9814] text-[#0E0E0E] font-[500] px-16 py-3 rounded-[30px] text-sm"
          onClick={() => {
            window.open('https://app.tiwiflix.io', '_self')
          }}
        >
          Home
        </button>}
      </div>
    </div>
  );
}
