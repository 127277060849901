import React from 'react'



const Modal = ({ isOpen, showFooter, children, handleClose, modalSize, closeBtnClass }) => {

    const modalClassName = isOpen ? `modal modal-open ${modalSize}` : "modal modal-close";

    return (
        <div className={modalClassName}>
            <div className="modal-mask">
                <div className="modal-wrapper">
                    <div className={`modal-container ${modalSize}`}>
                        <div className="modal-header">
                            <button
                                className={`close-button editorial ${closeBtnClass}`}
                                onClick={() => handleClose()}
                            >x
                            </button>
                        </div>
                        {children}
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Modal