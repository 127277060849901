import {

    FacebookIcon,

    FacebookShareButton,
    FacebookShareCount,

    TelegramIcon,
    TelegramShareButton,


    TwitterShareButton,

    WhatsappIcon,
    WhatsappShareButton,

    TwitterIcon,
} from "react-share";


export function SocialShare({ shareUrl, title }) {

    return (
        <div className="flex flex-col gap-10">
            <div className="Demo__container w-full grid grid-cols-4 gap-4 flex-wrap">

                <div className="Demo__some-network flex flex-col items-center justify-center">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        className="Demo__some-network__share-button share-btn"
                    >
                        <WhatsappIcon size={'48'} borderRadius={10} />
                        <p className="text-center  mt-1 font-[500]  text-xs dark:text-white">WhatsApp</p>
                    </WhatsappShareButton>
                </div>

                <div className="Demo__some-network flex flex-col items-center justify-center">
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className="Demo__some-network__share-button share-btn mx-auto"
                    >
                        <TwitterIcon size={'50'} borderRadius={10} />
                        <p className="text-center mt-1 font-[500]  text-xs dark:text-white">Twitter</p>
                    </TwitterShareButton>

                </div>

                <div className="Demo__some-network flex flex-col items-center justify-center">
                    <FacebookShareButton
                        url={shareUrl}
                        className="Demo__some-network__share-button share-btn mx-auto"
                    >
                        <FacebookIcon size={'48'} borderRadius={10} />
                        <p className="text-center mt-1 font-[500]  text-xs dark:text-white">Facebook</p>

                    </FacebookShareButton>
                </div>

                <div className="Demo__some-network flex flex-col items-center justify-center">
                    <TelegramShareButton
                        url={shareUrl}
                        title={title}
                        className="Demo__some-network__share-button share-btn mx-auto"
                    >
                        <TelegramIcon size={'48'} borderRadius={10} />
                        <p className="text-center mt-1 font-[500]  text-xs dark:text-white">Telegram</p>
                    </TelegramShareButton>
                </div>
            </div>

            <button
                onClick={() => {
                    navigator.clipboard.writeText(`${shareUrl}`)
                }}
                className="text-neutral-800 flex bg-[#ff9814] items-center rounded-[30px] p-3 py-2.5 text-xs w-2/2 flex-items-center justify-center"
            >
                <p className="text-neutral-800 text-sm font-medium flex items-center gap-1">Copy Link
                    <svg

                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                    </svg></p>
            </button>
        </div>
    );
}
